import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { connect } from 'react-redux';
import Seo from '../components/Seo'
import { setParticipantData, setActiveParticipant, activateParticipant } from '../state/actions'

const ParticipantTemplate = (props) => {
  const { data, setParticipantData, setActiveParticipant, activateParticipant, participants } = props;
  const {
    id,
    databaseId,
    title,
    mi_participant,
  } = data.wpParticipant


  const newParticipant = {...mi_participant, title, id: databaseId}

  useEffect(() => {
    if(mi_participant && participants.length){
      console.log('mi_participant && participants)', participants)
      setParticipantData(newParticipant)
      setActiveParticipant(databaseId)
      activateParticipant(databaseId)
    }
  }, [participants.length]);
  return (
    <>
      <Seo slug="erkunden" title={`${title} - Moabiter Insel`} description={`${title} ist Teil der Moabiter Insel.`}  keywords="" noFollow={false} noIndex={false} seoTitle={`${title} - Moabiter Insel`} url="https://www.moabiterinsel.de/erkunden/" />
    </>
  )
}

export const query = graphql`
    query SingleParticipant($databaseId: Int! = 5) {
      allWpParticipant(sort: {order: ASC, fields: mi_participant___miParticipantShorttitle}) {
        nodes {
          databaseId
          id
          slug
          title
          uri
          participantCategories {
            nodes {
              databaseId
              name
            }
          }
          mi_participant {
            miParticipantShorttitle
            miParticipantHeroSlider {
              imageFile {
                childImageSharp {
                  fluid(maxHeight: 540, maxWidth: 960, quality: 80, cropFocus: CENTER) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
              }
            }
            miParticipantGeodata {
              latitude
              longitude
              streetName
              streetNumber
              placeId
              city
              postCode
              streetAddress
            }
          }
        }
      }
        wpParticipant(databaseId: {eq: $databaseId}) {
          id
          databaseId
          title
          slug
          uri
          seo {
            metaDesc
            metaKeywords
            metaRobotsNofollow
            metaRobotsNoindex
            title
            opengraphUrl
          }
          participantCategories {
            nodes {
              id
              name
              databaseId
              slug,
              uri
            }
          }
          mi_participant {
            miParticipantShorttitle
            miParticipantContent
            miParticipantFacebook
            miParticipantGeodata {
              city
              latitude
              longitude
              placeId
              postCode
              streetAddress
              streetName
              streetNumber
            }
            miParticipantHeroSlider {
              databaseId
              mimeType
              mediaType
              title
              altText
              imageFile {
                childImageSharp {
                  fluid(maxHeight: 540, maxWidth: 960, quality: 80, cropFocus: CENTER) {
                    ...GatsbyImageSharpFluid_noBase64
                    }
                }
              }
            }
            miParticipantInstagram
            miParticipantOpeningTimes {
              miParticipantOpeningTimesDay
              miParticipantOpeningTimesTimeframe
            }
            miParticipantOpeningTimesTextarea
            miParticipantPhone
            miParticipantPrivateWebsite
            miParticipantSubheadline
            miParticipantTwitter
            miPictureCredits
          }
        }

    }
`

const mapStateToProps = (state) => {
  return {
    participants: state.participants.items,
  }
}

const mapDispatchToProps = {
  setParticipantData,
  setActiveParticipant,
  activateParticipant
};

export default connect(mapStateToProps, mapDispatchToProps)(ParticipantTemplate)
